<template>
<main class="main" role="main">
    <div class="main-container">

        <div class="timezone-container mw-100">
            <h1 class="heading">
                List of GMT offset time zones
            </h1>
            <p>
                This list contains 36 GMT offsets. Don't forget, this list is not constant (depend on, is daylight saving time applied or not).
                But what is GMT? It is abbreviation for Greenwich Mean Time. GMT is the mean solar time at the Royal Observatory in Greenwich (London, UK). It was formerly used as the international civil time standard. Time zones on east from Greenwich have positive offset and on west: negative.
            </p>
            <!-- <div style="display: flex; flex-grow: 1; justify-content: center">
                <div id="waldo-tag-12625"></div>
            </div> -->
            <div class="timezone-flex">
                <a style="font-size: 15px;" :href="$router.resolve({name: 'gmtquery', params: {q: r.name}}).href" v-for="r in regions" v-bind:key="r.name">
                {{r.name}} ~ <small> ({{r.count}}) </small>
                </a>
                <!-- <router-link style="font-size: 15px;" :to="{path:'/gmt/'+r.name}" v-for="r in regions" v-bind:key="r.name">
                {{r.name}} ~ <small> ({{r.count}}) </small>
                </router-link> -->
                <img v-if="loading" src="@/assets/loading.gif">
            </div>
            <!-- <div style="display: flex; flex-grow: 1; justify-content: center; margin-top: 6px">
            <div id="waldo-tag-12626"></div>
            </div> -->
        </div>
        
    </div>
      <footers></footers>
</main>
</template>

<script>
import axios from 'axios'
import moment from "moment"
import footers from '@/views/Footer.vue'
import Autocomplete from 'vuejs-auto-complete'
export default {
    components: {
        footers,
        Autocomplete
    },
    data() {
        return {
            events: [],
            tz: {},
            file_url: this.$store.state.file_url,
            homePage: [],
            api_url: "https://howmanydaystill.com/_read",
            regions: [],
            tz: false,
            loading: false,
            cityAuto: "",
            // api_url:"http://localhost:5000/_read"
        }
    },
    watch: {
        '$route': {
            handler(newValue, oldValue) {
                this.tz = this.$route.params.region
                this.init()
            }
        },
        '$router.params': {
            handler(newValue, oldValue) {
                console.log(3)
        //           setTimeout(d=>{
        //          waldo.refreshTag("waldo-tag-12625")
        //          waldo.refreshTag("waldo-tag-12626")
        // },1000)
            }
        }
    },
    created() {
        this.tz = this.$route.params.region
        this.init()
        // setTimeout(d=>{
        //          waldo.refreshTag("waldo-tag-12625")
        //          waldo.refreshTag("waldo-tag-12626")
        // },1000)
    },
    methods: {
        setCity() {
            setTimeout(f => {
                this.$router.push({
                    path: "/" + this.cityAuto
                });
            }, 200)

        },
        init() {
            this.loading = true;
            this.regions = []
            axios.get(this.$store.state.api_url + "_/_gmt").then(response => {
                this.regions = (response.data.data)
                this.loading = false
            }).catch(error => {

            })
        }
    }
}
</script>

<style scoped>
.auto {
    width: 97%;
    margin: 0 auto;
    margin-top: 25px;
}
</style>
